import React, {useRef} from 'react';
import './sass/App.scss';
import {ThemeProvider, CssBaseline} from '@mui/material';

import Login from './components/Login';
import FAQ from './components/FAQ';

import Kontakt from './components/Kontakt';

import {BrowserRouter as Router} from 'react-router-dom';
import PopUp from './components/popups/PopUp';
import {reduxStore} from './reducer/combineReducer';
import {Provider} from 'react-redux';
import UiMessages from './components/ui/uiMessages';
import {getLocation} from './lib/locations';
import {getTheme} from './lib/getTheme';
import Header from './components/shared/Header';
import Datenschutz from './components/Datenschutz';
import Footer from './components/shared/Footer';
import NewCode from './components/NewCode';

function App() {

  const loginRef = useRef('login');
  const faqRef = useRef('faq');
  const kontaktRef = useRef('kontakt');
  const containerRef = useRef('container');

  const location = getLocation();
  const theme = getTheme(location);

  return (
      <Provider store={reduxStore}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Datenschutz/>
          {location && (<Header/>)}
          <PopUp/>
          <UiMessages/>
          <Router>
            {/*//@ts-ignore*/}
            <div ref={containerRef}>
              <Login ref={loginRef}
                     key={loginRef}/>
              <FAQ ref={faqRef}
                   key={faqRef}/>
              <NewCode/>
              <Kontakt ref={kontaktRef}
                       key={kontaktRef}/>
            </div>
          </Router>
          <Footer/>
        </ThemeProvider>
      </Provider>
  );
}

export default App;
