import {Button, Container, Stack, styled} from '@mui/material'
import {getLocation, getLocationData} from '../../lib/locations'
import {Phone} from '@mui/icons-material'

const CustomHeader = styled('header')(({theme}) => ({
  background: theme.palette.primary.dark,
  padding: theme.spacing(2, 0),
}))

const HeaderContainer = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}))

const Header = () => {

  const location = getLocation()
  const data = getLocationData()
  if (!data) {
    return <div></div>
  }

  return (
      <CustomHeader
          // // @ts-ignore
          // sx={data.options.customHeaderColor !== null ?
          //     // @ts-ignore
          //     {background: data.options.customHeaderColor} :
          //     {}}
      >
        <HeaderContainer>
          <div className="logo">
            <img src={data.logo} alt=""/>
          </div>
          <Stack spacing={3} direction={'row'}>
            <Button variant={'outlined'} color={'secondary'}
                    href={'tel:' + data.tel.link}
                    sx={{display: {xs: 'none', md: 'block'}}}>
              {data.tel.text}
            </Button>
            <Button variant={'contained'} color={'secondary'} href={data.link}
                    target={'_blank'}>
              Zurück zur Webseite
            </Button>
          </Stack>
        </HeaderContainer>
      </CustomHeader>
  )
}

export default Header