import React from 'react'
import {Container, Grid, styled, Typography} from '@mui/material'
import LoginFAQ from '../FAQ/LoginFAQ'
import BilderFAQ from '../FAQ/BilderFAQ'
import BefundFAQ from '../FAQ/BefundFAQ'

const FAQContainer = styled(Grid)(({theme}) => ({
  color: '#fff',
  padding: theme.spacing(4),
  background: theme.palette.primary.main,
}))

export default function FAQ({...props}: any) {
  return (
      <section id={'faq'}>
        <Grid container spacing={0}
              sx={{minHeight: {md: '100vh'}}}>
          <Grid item xs={12} md={4} xl={3} className={'contentContainer'}>
            <Container sx={{position: 'sticky', top: '1em'}}>
              <Typography variant={'topline'}>FAQs</Typography>
              <Typography variant={'h2'}>
                Wir beantworten Ihre <strong>Fragen</strong>
              </Typography>
              <Typography variant={'body1'}>
                Sie benötigen Hilfe beim Login? Sie wissen nicht genau, wo Sie
                Ihren Befund finden oder wie
                Sie die Daten Ihrem Arzt weiterleiten? Hier finden Sie Antworten
                zu den häufigsten Fragen
                und Probleme.
              </Typography>
            </Container>
          </Grid>
          <FAQContainer container item xs={12} md={8} xl={9} spacing={0}
                        justifyContent={'center'}
                        direction={'column'}>
            <Container>
              <LoginFAQ/>
              <BilderFAQ/>
              <BefundFAQ/>
            </Container>
          </FAQContainer>
        </Grid>
      </section>
  )
}