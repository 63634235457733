import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Button, Grid, Container} from '@mui/material';
import homeImg from './home.png';
import dateVideo from './Anleitung.mp4';
import LoginButton from '../components/shared/LoginButton';
import {handlePopUp} from '../actions/popupActions';
import {useDispatch} from 'react-redux';
import {POPUP_TYPES} from '../actions/popupActions';

export default function LoginFAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const dispatch = useDispatch();

  const handleNewCodeForm = () => {
    dispatch(handlePopUp(POPUP_TYPES.NEW_CODE));
  };

  const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

  return (
      <Container className={'faqSection'}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant={'h4'} variantMapping={{'h4': 'h2'}}
                        sx={{color: '#ffffff', m: 0}}>Zugang /
              Login</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel4'}
                           onChange={handleChange('panel4')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wo finde ich den Login?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Button variant={'contained'} color={'secondary'}
                            href={'https://portal.rh-dt.de/'}>
                      zum Patienten-Login
                    </Button>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'}
                           onChange={handleChange('panel1')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie erhalte ich meinen persönlichen Zugangsdaten?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Als Patient/Patientin erhalten Sie nach der Untersuchung
                      einen Ausdruck mit
                      Ihren
                      persönlichen
                      Zugangsinformationen (Zugangscode und Geburtsdatum) und
                      dem Link zum Login.
                      Alternativ
                      können
                      Sie den QR-Code auf dem Ausdruck scannen. Sie gelangen
                      dann direkt zur
                      Login-Seite
                      und
                      müssen
                      sich nur noch mit Ihrem Geburtsdatum authentifizieren.
                    </Typography>
                    <LoginButton/>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'}
                           onChange={handleChange('panel2')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Was kann ich tun, wenn ich meinen Code nicht mehr finde?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Sie erhalten einen neuen Zugangscode, wenn Sie das
                      Formular abschicken.
                    </Typography>
                    <Button variant={'contained'} color="secondary"
                            onClick={handleNewCodeForm}
                            sx={{my: 3}}>
                      Zum Formular
                    </Button>
                    {
                      // TODO forgot password form
                    }
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel3'}
                           onChange={handleChange('panel3')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>Was sehe ich nach dem
                      Login?</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <img src={homeImg}/>
                    <Typography>
                      Initial landen Sie auf dem Reiter „Bilder“ und ihrer
                      letzten Untersuchung. Sie
                      können
                      nun über
                      die Klappmenüs auf der linken Seite weitere Untersuchungen
                      öffnen. Die
                      Steuerelemente
                      befinden
                      sich rechts neben den Bilddaten.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'}
                           onChange={handleChange('panel5')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Was passiert mit meinen Bildern und dem Zugang nach sechs
                      Monaten?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>Der Zugang über dem QR-Code läuft nach sechs
                      Monaten ab. Das MVZ
                      archiviert die Daten aber mindestens 10 Jahre. Sie können
                      sich jederzeit im MVZ einen neuen QR-Code abholen. Die
                      alten Bilder sind
                      mit einem neuen Code
                      auch
                      aufrufbar.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'}
                           onChange={handleChange('panel6')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie gebe ich mein Geburtsdatum ein?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <video src={dateVideo} controls/>
                    <Typography>
                      Der Kalender von den Browsern mobiler Betriebssysteme
                      macht das Navigieren
                      des
                      Geburtsdatums auf den ersten Blick schwer.
                      <br/>
                      <strong>Lösung iOS: </strong> Es kann oben im Kalender auf
                      den Monat
                      geklickt
                      und anschließend
                      das Jahr und der Monat über ein Rad einfach ausgewählt
                      werden.
                      <br/>
                      <strong>Lösung Android:</strong> Es kann oben über dem
                      Kalender auf das Jahr
                      geklickt
                      werden und anschließend kann einfach in das richtige Jahr
                      navigiert werden,
                      anschließend der Monat und dann der Tag.
                    </Typography>
                    <Typography>
                      Im Video sehen Sie, wie Sie Ihr Geburtsdatum bei Android-
                      und bei
                      Apple/iOS-Geräten eingeben können.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
  );
}