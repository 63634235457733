import React, {useState} from 'react';
import {
  Container,
  Grid,
  Typography,
  styled, FormGroup, FormLabel, Switch, Stack, ThemeProvider, Box,
} from '@mui/material';
import ContactForm from './forms/ContactForm';
import NewCodeForm from './forms/NewCodeForm';
import styles from './kontakt.module.css';

const ContactFormGridItem = styled(Grid)(({theme}) => ({
  background: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
}));

export default function Kontakt({...props}: any) {

  const [value, setValue] = useState<Date | null>(null);
  const [displayNewCodeForm, setDisplayNewCodeForm] = useState(true);

  return (
      <section id={'kontakt'}>
        <Grid container spacing={0} sx={{minHeight: '100vh'}}
              className={'zuweiser'}>
          <Grid item xs={12} md={4} xl={3} className={'contentContainer'}>
            <Container sx={{position: 'sticky', top: '1em'}}>
              <Typography variant={'topline'}>Kontakt</Typography>
              <Typography variant={'h2'}>
                Technischer <strong>Support</strong>
              </Typography>
            </Container>
          </Grid>
          <ContactFormGridItem item xs={12} md={8} xl={9} spacing={2}
                               sx={{
                                 minHeight: {md: '100vh'},
                                 paddingBottom: {xs: 8, md: 0},
                               }}
                               justifyContent={'center'}>
            <Container sx={{py: 3}}
                       className={'contactFormWrapper'}>
              <ContactForm/>
            </Container>
          </ContactFormGridItem>

        </Grid>
      </section>
  );
}