import {Box, Container, Grid, Typography, useTheme} from '@mui/material';
import NewCodeForm from './forms/NewCodeForm';

function NewCode() {

  const theme = useTheme();

  return (
      <section id={'zugangscode'}>
        <Grid container sx={{minHeight: {md: '100vh'}}}>
          <Grid item xs={12} md={4} xl={3}>
            <Container sx={{
              position: 'sticky', top: '1em',
            }}>
              <Typography
                  variant={'h2'}>Neuer <strong>Zugangscode</strong></Typography>
            </Container>
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <Box sx={{
              height: '100%',
              background: theme.palette.grey[300],
            }}>
              <NewCodeForm options={{my: 0}}/>
            </Box>
          </Grid>
        </Grid>
      </section>
  );
     
}

export default NewCode;