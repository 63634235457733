import {LOCATIONS} from './locations'
import {defaultTheme} from './themes/defaultTheme'
import {createCustomTheme} from './customTheme'

export const radiosTheme = createCustomTheme('#1995d9', '#770033')
export const dinkelsbuehlTheme = createCustomTheme('#c79b3a', '#6E6E6E')
export const strahleninstitutTheme = createCustomTheme('#1f3a87', '#DC6D73')

export const getTheme = (location: string | null) => {
  switch (location) {
    case LOCATIONS.RADIOS:
      return radiosTheme
    case LOCATIONS.STRAHLENINSTITUT:
      return strahleninstitutTheme
    case LOCATIONS.DINKELSBUEHL:
      return dinkelsbuehlTheme
    default:
      return defaultTheme
  }
}
