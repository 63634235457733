import React, {useState} from 'react';
import {
  Button, Checkbox, FormControl, FormControlLabel,
  FormGroup, FormLabel,
  Grid, MenuItem, Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {inputEl} from '../../helper/Validator';
import {Validator} from '../../helper/Validator';
import {sendContactForm} from '../../helper/api';
import {useDispatch} from 'react-redux';
import {handleSuccessMsg} from '../../actions/uiActions';
import classes from './contactForm.module.css';
import {emptyLocations, getLocation, locations} from '../../lib/locations';
import {handlePopUp, POPUP_TYPES} from '../../actions/popupActions';

export interface valuesState {
  firstname: inputEl,
  lastname: inputEl,
  email: inputEl,
  birthdate: inputEl,
  number: inputEl,
  content: inputEl
}

const initLocationGiven = getLocation() !== null;

const LightInput = styled(TextField)(({theme}) => ({
  background: 'rgba(255,255,255,0.11)',
  '& .MuiFilledInput-root':
      {
        color: '#fff',
      }
  ,
  '& .MuiInputLabel-root':
      {
        color: '#fff',
      },

}));

const initialValues: valuesState = {
  firstname: {value: ''},
  lastname: {value: ''},
  email: {value: '', required: true},
  birthdate: {value: '', required: false},
  number: {value: ''},
  content: {value: '', required: true},
};

export default function ContactForm() {

  const [values, setValues] = useState<valuesState>(initialValues);
  const [privacy, setPrivacy] = useState({checked: false, error: false});
  const [currentLocation, setCurrentLocation] = useState<string | null>(
      getLocation());
  const [locationError, setLocationError] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (prop: keyof valuesState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleValue(prop, {value: event.target.value});
  };

  const handleSelect = (event: any) => {
    setLocationError(false);
    setCurrentLocation(event.target.value);
  };

  const handleFocusLeave = (prop: keyof valuesState) => (event: React.FocusEvent<HTMLInputElement>) => {
    if (values[prop].required && values[prop].value.length === 0) {
      return setValues({
        ...values,
        [prop]: {
          ...values[prop],
          error: false,
        },
      });
    }
    else {
      handleValidation(prop);
    }

  };

  const handleCheck = () => {
    setPrivacy(privacy => ({
      checked: !privacy.checked,
      error: privacy.checked,
    }));
  };

  const handleValidation = (key: keyof valuesState | string) => {

    //@ts-ignore
    if (!values[key].required && values[key].value.length === 0) {
      //@ts-ignore
      return {...values[key], error: false};
    }
    switch (key) {
      case'firstname':
      case'lastname':

        return handleValue(key, Validator.validateText(values[key]));
      case'content':

        return handleValue(key, Validator.validateTextArea(values[key]));
      case 'email':
        return handleValue(key, Validator.validateEmail(values[key]));
      case 'number':
        return handleValue(key, Validator.validateNumber(values[key]));
      case 'birthdate':
        return handleValue(key, Validator.validateDate(values[key]));
    }
  };

  const displayedLocations = [...locations, ...emptyLocations].sort(
      (a, b) => a.clearName.localeCompare(b.clearName));

  const handleValue = (key: keyof valuesState, inputEl: inputEl) => {
    setValues({
      ...values,
      [key]: {
        ...values[key],
        ...inputEl,
      },
    });
  };

  const handleSubmit = () => {
    let submitting = true;
    for (const [key, inputEl] of Object.entries(values)) {
      handleValidation(key);
      if (inputEl.error === true) {
        submitting = false;
      }
    }
    // Check if privacy is checked
    if (!privacy.checked) {
      setPrivacy({
        ...privacy,
        error: true,
      });
      submitting = false;
    }

    // Check if Location is given
    if (currentLocation === null) {
      setLocationError(true);
      submitting = false;
    }
    const data = {
      place: currentLocation,
    };
    if (submitting) {
      Object.keys(values).forEach((key) => {
        //@ts-ignore
        data[key] = values[(key as keyof valuesState)].value;
      });
      handleEmail(data);
      setValues(initialValues);
      dispatch(handleSuccessMsg('Vielen Dank für Ihre Anfrage. Sie haben' +
          ' eine Bestätigungs-Email von uns erhalten. Bitte überprüfen' +
          ' Sie ebenfalls Ihren Spam-Ordner.'));
    }
  };

  const handleEmail = (data: {}) => {
    sendContactForm(data).then(res => {
      return;
    }).catch(e => {
      console.error(e);
    });
  };

  const handleNewCodeForm = () => {
    dispatch(handlePopUp(POPUP_TYPES.NEW_CODE));
  };

  return (
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12}>
          <Typography variant={'h3'} component={'h2'}>
            Technischer Kontakt
          </Typography>
          <Typography variant={'body2'} sx={{color: '#fff'}}>
            Wenn Sie technische Fragen zum Patientenportal und der Anwendung
            haben, die nicht in den FAQs auf dieser Seite beantwortet werden,
            können Sie uns über das Formular eine Nachricht schicken.
          </Typography>

          <Typography variant={'body2'} sx={{color: '#fff', my: 3}}>
            Bitte beachten Sie, dass wir keine medizinischen Auskünfte zu
            Ihren
            Befunden oder Bildern geben können und diese Anfragen daher nicht
            beantworten. Für medizinische Nachfragen wenden Sie sich bitte an
            Ihr MVZ, welches die Bilder und Befunde erstellt hat.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <LightInput label={'Vorname'} name={'firstname'} variant={'filled'}
                      value={values.firstname.value}
                      error={values.firstname.error}
                      onChange={handleChange('firstname')} fullWidth
                      onBlur={handleFocusLeave('firstname')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LightInput label={'Nachname'} name={'lastname'} variant={'filled'}
                      fullWidth
                      value={values.lastname.value}
                      error={values.lastname.error}
                      onChange={handleChange('lastname')}
                      onBlur={handleFocusLeave('lastname')}
          />
        </Grid>
        <Grid item xs={12}>
          <LightInput label={'Email-Adresse'} name={'email'} type={'email'}
                      variant={'filled'}
                      required fullWidth value={values.email.value}
                      error={values.email.error}
                      onChange={handleChange('email')}
                      onBlur={handleFocusLeave('email')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LightInput label={'Geburtsdatum (TT.MM.JJJJ)'} name={'birthdate'}
                      variant={'filled'}
                      fullWidth value={values.birthdate.value}
                      error={values.birthdate.error}
                      onChange={handleChange('birthdate')}
                      onBlur={handleFocusLeave('birthdate')}
          />
        </Grid>


        <Grid item xs={12} md={6}>
          <LightInput label={'Telefonnummer'} name={'number'}
                      variant={'filled'}
                      fullWidth value={values.number.value}
                      error={values.number.error}
                      onChange={handleChange('number')}
                      onBlur={handleFocusLeave('number')}
          />
        </Grid>
        {!initLocationGiven && (
            <Grid item xs={12}>
              <FormControl fullWidth error={locationError}>
                <Typography variant={'h6'} component={'h4'}
                            sx={{color: '#fff'}}>
                  Bitte wählen Sie ihr MVZ aus*
                </Typography>
                <Select onChange={handleSelect} sx={{
                  background: 'rgba(255,255,255,0.11)',
                  '& .MuiSelect-select':
                      {color: '#fff'},
                  'svg':
                      {fill: '#fff'},
                }}
                        value={currentLocation}
                >
                  {displayedLocations.map(location => (
                      <MenuItem key={location.clearName}
                                value={location.clearName}>{location.clearName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
        )}
        <Grid item xs={12}>
          <LightInput variant={'filled'} name={'content'} fullWidth
                      onChange={handleChange('content')} required
                      onBlur={handleFocusLeave('content')}
                      value={values.content.value}
                      error={values.content.error}
                      label={'Nachricht'} multiline rows={3}/>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={privacy.error}>
            <FormGroup className={classes.root}>
              <Checkbox
                  onClick={handleCheck}
                  checked={privacy.checked}
                  sx={{
                    color: '#fff',
                    '&.Mui-checked': {color: '#fff'},
                  }}/>
              <FormLabel className={classes.label}
                         sx={{color: privacy.error ? 'red' : 'inherit'}}
              >
                Ich habe die <a
                  href="https://www.rh-dt.de/datenschutz">Datenschutzerkärung</a> gelesen
                und akzeptiere die Verarbeitung meiner personenbezogenen Daten.
              </FormLabel>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button color={'secondary'} onClick={handleSubmit}
                  variant={'contained'}>Senden</Button>
        </Grid>
      </Grid>
  );
}