import React, {useState} from 'react'
import {Alert, AlertTitle, Box, Collapse} from '@mui/material'

export default function Datenschutz() {

  const [open, setOpen] = useState(true)

  return (
      <Box sx={{
        position: 'fixed',
        p: 3, zIndex: 1000,
      }}>
        <Collapse in={open}>
          <Alert severity={'info'} onClose={() => {
            setOpen(false)
          }} onClick={() => {
            setOpen(false)
          }}>
            <AlertTitle>Hinweis – Ihre Daten sind bei uns sicher!</AlertTitle>
            Ihre Daten werden auf unseren eigenen Servern
            sicher gespeichert. Wir geben Ihre Daten nicht weiter und arbeiten
            komplett konform mit der DSGVO. Das verlangen wir auch von all
            unseren
            Partnern. Für Ihre Sicherheit – und die Ihrer Daten.
          </Alert>
        </Collapse>
      </Box>
  )
}