type StringValueOf<T> = T[keyof T] & string

export const LOCATIONS = {
  RADIOS: 'radios',
  STRAHLENINSTITUT: 'strahleninstitut',
  DINKELSBUEHL: 'dinkelsbuehl',
  RH: 'rh-dt.de',
};

export interface locationEmpty {
  clearName: string,
  email: string,
}

interface location {
  paramName: StringValueOf<typeof LOCATIONS>
  clearName: string,
  link: string,
  email: string,
  logo: string,
  tel: {
    link: string,
    text: string
  }
  options?: {
    customHeaderColor: string,
  }

}

export const emptyLocations: locationEmpty[] = [
  {
    clearName: 'Braunschweig: MVZ Radiologie Braunschweig',
    email: 'info@radiologie-bs.de',
  },
  {
    clearName: 'Helmstedt: Radiologie Helmstedt',
    email: 'info@radiologie-helmstedt.de',
  },
  {
    clearName: 'Köln: Radiologie und Nuklearmedizin Ebertplatz Köln',
    email: 'info@radiologie-ebertplatz.de',
  },
  {
    clearName: 'Gelsenkirchen: MVZ Radiologie Dr. Lorenz & Triebe GmbH',
    email: 'info@radiologie-lorenz-triebe.de',
  },
  {
    clearName: 'Essen: Ruhrradiologie Essen',
    email: 'info@ruhrradiologie-essen.de',
  },
  {
    clearName: 'Kleve: MVZ Radiologie Unterer Niederrhein',
    email: 'info@radiologie-kleve.de',
  },
  {
    clearName: 'Wesel: MVZ Radiologie im Forum, Praxis für Radiologie und ' +
        'Nuklearmedizin GmbH', email: 'info@radiologie-wesel.de',
  },
  {
    clearName: 'Stuttgart: Radiologie Stuttgart',
    email: 'info@radiologie-stuttgart.de',
  },
  {
    clearName: 'Stuttgart: Radiologikum Stuttgart',
    email: 'info@radiologikum-stuttgart.de',
  },

];

export const strahleninstitut: location = {
  paramName: LOCATIONS.STRAHLENINSTITUT,
  clearName: 'Köln: MVZ CDT Strahleninstitut GmbH',
  link: 'https://strahleninstitut.de/',
  email: 'info@strahleninstitut.de',
  logo: 'https://strahleninstitut.de/typo3conf/ext/strahleninstitut/Resources/Public/Images/strahleninstitut-logo.png',
  tel: {
    link: '022199502100',
    text: '0221 - 99 502 100',
  },
};

export const dinkelsbuehl: location = {
  paramName: LOCATIONS.DINKELSBUEHL,
  clearName: 'Dinkelsbühl: Radiologie Dinkelsbühl',
  link: 'https://radiologie-dinkelsbuehl.de/home.html',
  email: 'info@radiologie-dinkelsbuehl.de',
  logo: 'https://radiologie-dinkelsbuehl.de/assets/images/0/logo_radiologie_dinkelsbuehl-44004ea0.png',
  tel: {
    link: '0985157260',
    text: '0 98 51 - 57 26 0',
  },
  options: {
    customHeaderColor: '#e5e5e5',
  },
};

export const radios: location = {
  paramName: LOCATIONS.RADIOS,
  clearName: 'Düsseldorf: MVZ Radios',
  link: 'https://www.mradios-praxen.de',
  email: 'info@radios-praxen.de',
  logo: 'https://www.radios-praxen.de/typo3conf/ext/radios/Resources/Public/Images/radios-logo.png',
  tel: {
    link: '021144773000',
    text: '0211 - 44 77 - 3000',
  },
};

export const locations = [
  radios, dinkelsbuehl, strahleninstitut,
];

export const getLocation = (): string | null => {
  const params = getParams();
  const location = params.get('location');
  return location;
};

export const getParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params;
};

export const getLocationData = () => {
  const location = getLocation();
  switch (location) {
    case LOCATIONS.RADIOS: {
      return radios;
    }
    case LOCATIONS.DINKELSBUEHL: {
      return dinkelsbuehl;
    }
    case LOCATIONS.STRAHLENINSTITUT: {
      return strahleninstitut;
    }
    default:
      return false;
  }
};


